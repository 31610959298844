// YOU SHOULD ONLY MODIFY THIS FILE AT `Websites/Frontend/src/common/pageup-utils.js`, ALL OTHER LOCATIONS ARE COPIED FROM THIS FILE

(function () {
    var callbacks = [];
    var waitingOnReady = false;

    // DOMContentLoaded utility function, if DOM is ready, will run callback straight away, or else add an event listener 
    document.ready = function (callback) {
        // Don't continue if callback isn't a function
        if(typeof callback !== "function") {
            return;
        }
        
        // Save callback for later when DOM is ready
        callbacks.push(callback);

        // if document ready to go, let callbacks know
        if (isDOMReady()) {
            return notifyCallbacks();
        } else if(!waitingOnReady) {
            waitingOnReady = true;
            // first choice is DOMContentLoaded event
            document.addEventListener("DOMContentLoaded", notifyCallbacks);
            // backup is window load event
            window.addEventListener("load", notifyCallbacks);
        }
    };

    function isDOMReady() {
        return document.readyState === "complete" || document.readyState === "interactive";
    }

    // DOM's loaded, let's get on with this
    function notifyCallbacks() {
        // Remove event listeners as they're not needed anymore
        document.removeEventListener("DOMContentLoaded", notifyCallbacks);
        window.removeEventListener("load", notifyCallbacks);
        
        // Iterate through callbacks, call them and reinit array
        for (var i = 0, len = callbacks.length; i < len; i++) {
            try {
                callbacks[i]();
            } catch(e) {
                console.error("document.ready callback error: " + e);
            }
            
        }
        callbacks = [];
    }
})();

